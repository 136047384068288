/**
 * Slideshow - Owl Carousel 2
 **/

.owl-nav{
    position: absolute;
    top: calc(50% - 40px);
    width: 100%;
}

.owl-prev,
.owl-next{
	&:hover{
		background: transparent !important;
	}
}

.owl-prev{
	position: absolute;
	left: -30px;
}

.owl-next{
	position: absolute;
	right: -5px;
}