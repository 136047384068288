/**
 * Search
 **/

.search-wrap-buttons{
	label{
		background-color: $black;
		color: $white;
		padding: 10px 15px;		
	}
}

/** 
 * Full page search
 **/

/**
 * Search
 **/

 .full-page-search{
	background-color: $pink;
	border: 10px solid $pink;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;

	z-index: 110;

	opacity: 0;
	visibility: hidden;
	transition: all 350ms;
	transform: scale(0.7);

	h2{
		color: $red;
		margin-top: 150px;
		margin-bottom: 75px;
		text-align: center;
	}

	input[type="search"]{
		background-color: transparent;
		box-shadow: none;
		border: none;
		border-bottom: 2px solid $red;
		color: $black;
		font-size: 26px;
		font-weight: 700;
		letter-spacing: 1px;

		&:focus,
		&:focus-visible,
		&:focus-within,
		&:active{
			appearance: none;
			box-shadow: 0 !important;
			border: 0 !important;
			border-bottom: 2px solid $red !important;	
			outline: none !important;
		}
	}

	button[type="submit"]{
		border: none;
		color: $red;
		padding-bottom: 0;
		font-size: 20px;
		background-color: $pink;
	}

 }

 .search-active .full-page-search{
 	opacity: 1;
 	border: 20px solid $white;
 	visibility: visible;
 	transform: scale(1);
 }


 /**
  * Close
  **/

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 1;
}
.close:hover {
	opacity: 1;

	&:before,
	&:after{
		background-color: $red;
	}
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: $red;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

/**
 * Search results
 **/

.search-results__item{
	padding-bottom: 25px;

	h2{
		font-size: 26px;
		margin-bottom: 0;
	}

	a{
		color: $black;
		text-decoration: none;
	}

	.read-more{
		color: $red;
	}
}
