/**
 * CTA
 **/

 .call-to-action{
 	&__inner{
		border-radius: 0 0 100px 0; 		
 		padding-left: 50px;
 		padding-right: 50px;
 		position: relative;

 		&:after{
 			background-color: $mustard;
 			content: '';
 			position: absolute;
 			width: 100px;
 			height: 100px;
 			z-index: -1;
 			right: 0;
 			bottom: 0;
 		}

  		p{
  			padding-top: 0;
  		}
 	}

 	h3{
 		font-family: $body-font;
 		font-size: 30px;
 	}
 }