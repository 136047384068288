/**
 * Single - Speakeres
 **/

.speaker{
	&__image-wrapper{
		background-color: $pink;

		img{
			border-radius: 400px 400px 0 0;
			width: 100%;
		}
	}
}

.specifications__inner{
	height: 100%;
	width: 100%;
	padding: 35px;

	h3{
		margin-bottom: 50px;
	}

	p{
		margin-top: 50px;
	}

	.specification{
		margin-bottom: 15px;

		strong{
	    	width: 150px;
	    	display: inline-block;		
		}

		span{
			display: inline-block;
		}
	}
}

.specification__languages{

	ul{
		padding-left: 0;
	}

	.language-wrap{
	    display: inline;
	    padding: 3px 5px;
	    border: 1px solid $black;
	    border-radius: 15px;
	    line-height: 33px;
	    text-transform: uppercase;
	    font-weight: 600;
	    font-size: 14px;
	    padding-right: 3px;
	}	
}