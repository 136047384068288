/**
 * Mobile only
 **/

@include media-breakpoint-down(lg) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: block !important;
			width: 100% !important;
		}

		ul{
			width: 100%;
		}
	}

	.navbar{
		display: block !important;
	}

	.navbar-toggler{
		float: right;
	}
}

#menu-entries{
    flex-direction: row-reverse;

	.dropdown.child{
		position: relative !important;
		.dropdown-sub{
			position: absolute !important;
			right: 0;
		}
	}
}

/**
 * Top navigation
 **/
.top-nav-wrapper{
	padding-top: 18px;
	padding-bottom: 18px;

	.btn--black-transparent{
    	padding-left: 10.5px;
    	padding-right: 10.5px;
	}

	.pull-right span{
		float: right;
	}
}

.tel{
	@include media-breakpoint-down(lg) {
		display: none;
	}

	a{
		border: 1.5px solid $black;
		border-radius: 25px;
		color: $black;
		font-weight: 600;
		padding: 10px 25px;
		text-decoration: none;

		transition: all .3s ease;

		&:hover{
			background-color: $black;
			color: $white;
		}
	}
}

.link-speaker{
	color: $red;
    font-weight: 600;
    text-decoration: none;
    margin-right: 15px;

    &:hover{
    	color: $red;
    	text-decoration: underline;
    }
}

.top-nav-wrapper{
	.link-speaker{
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.language-switch-btn{
	position: relative;

	&:hover{
		span{
			opacity: 1;
			display: inherit;
		}
	}

	span{
		opacity: 0;
		display: none;
		position: absolute;
	    top: 50px;
	    right: 0;
	    background-color: #000;
	    color: #FFF;
	    padding: 15px;
	    text-align: center;
	    z-index: 1337;
	    border-radius: 20px;

	    transition: all 350ms;
	}
}


/**
 * Main navigation
 **/
.main-nav-wrapper{
	background-color: $red;
	padding-top: 15px;
	padding-bottom: 15px; 
}

.navbar-light .navbar-nav .nav-link{
	color: $black !important;
	font-weight: 600;
	margin-left: 30px;
	position: relative;

    &:before{
    	content:'';
    	position: absolute;
			
		background-color: $mustard;
		border-radius: 3px;
		height: 2px;
    	width: 0%;
    	left: 0;
    	bottom: 0;

    	transition: all 350ms;
    }	

    &:hover{
    	&:before{
   			width: 100%;
    	}
    }

	@include media-breakpoint-down(xl) {
		margin-left: 10px;
	}
}

.navbar-light .navbar-nav .btn-nav .nav-link{
    border: 2px solid $black;
    border-radius: 50px;
    padding: 7px 25px;	

    &:before{
    	display: none;
    }

    &:hover{
    	background-color: $black;
    	color: $red !important;
    }
}

.dropdown-nav-item{
	.nav-link{
		margin-left: 0 !important;
	}
}

.navbar-brand img{
	@include media-breakpoint-down(xl) {
		max-width: 240px;
	}	

	@include media-breakpoint-down(lg) {
		max-width: 180px;
	}	
}

/**
 * Search icon
 **/
.search-widget {
	margin-left: 15px;

	@include media-breakpoint-down(lg){
		margin-left: 15px;
    	position: absolute;
    	right: 80px;
    	top: 10px;
    }
}