/**
 * Footer
 **/

.bottom-footer{
	@extend .block-75;

	background: linear-gradient(90deg, $white 0%, $white 46.66%, $pink 46.66%, $pink 100%);
	border-radius: 0px 0px 100px 0px;
	position: relative;

	&:before{
		content:'';
		position: absolute;
		height: 1px;
		width: 46.66%;
		background-color: $purple;
		top: 0px;		
	}

	&:after{
		content:'';
		position: absolute;
		height: 1px;
		width: 46.66%;
		background-color: $purple;
		bottom: 0px;
	}

	h6{
		font-size: 20px;
	}

	ul{
		margin-top: 15px;
		padding-left: 0;
		list-style-type: none;

		a{
			color: $black;
			text-decoration: none;
			transition: all 350ms;

			&:hover{
				color: $red;
			}
		}
	}

	span{
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.btn--black-transparent{
    	padding-left: 10.5px;
    	padding-right: 10.5px;
	}	

	@include media-breakpoint-down(xl){
		background: $pink;

		&:before,
		&:after{
			display: none;
		}
	}

	@include media-breakpoint-down(sm){
		h5,
		h6{
			margin-top: 2.5rem;
		}
	}	
}

/**
 * Social icons
 **/
.social-icons{
	a{
		text-decoration: none;
	}

	img{
		margin-right: 5px;
	}
}


/**
 * Laatste blogs
 **/
.latest-blogs{
	ul{
		list-style-type: inherit;

		@include media-breakpoint-down(sm){
			list-style-type: none;
		}
	}
}

/**
 * Inschrijven op nieuwsbrief
 **/
.newsletter-subscribe{
	input{
		float: left;
		height: 40px;
		border-radius: 40px;
		box-shadow: 0;
		border: 0;
		text-align: center;
	}

	input[type="email"]{
		background-color: $white;
		width: 60%;		

		@include media-breakpoint-down(sm){
			width: 100%;
		}
	}

	input[type="submit"]{
		background-color: $red;
		color: $white;
		font-weight: 600;
		font-size: 14px;
		text-transform: uppercase;
		width: 40%;

		@include media-breakpoint-down(sm){
			width: 100%;
		}
	}
}


/**
 * Bottom footer
 **/
.footer-final{
  	color: #696C6A;
  	font-size: 12px;
  	text-decoration: none;

  a{
  	color: #696C6A;
  	font-size: 12px;
  	text-decoration: none;
  }
}
