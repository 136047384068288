/**
 * Forms
 **/

/* contact form 7 wrapper */
.contactform-wrapper .wpcf7{
	label{
		display: block;
		font-family: $heading-font;
		font-size: 20px;
	}

	input{
		border: 0;
		height: 50px;
		margin-bottom: 15px;
		padding-left: 15px;
		width: 100%;

		&:focus{
			outline: 0;
		}
	}

	textarea{
		border: 0;
		height: 125px;
		width: 100%;
	}

	input[type="submit"]{
		@extend .btn;
		@extend .btn--black;

		margin-top: 50px;
		width: auto;
	}
}

.cd-upload-btn{
	color: $red;

	&:hover{
		color: $pink;
	}
}