/**
 * Front
 **/

.header{
	&--home{
		background: linear-gradient(90deg, $mustard 0%, $mustard 50%, #F2F2F2 50%, #F2F2F2 100%);

		.header__title{	
			h1{
				font-size: 60px;
				max-width: 450px;
				position: relative;

				&:after{
					content:'';
					position: absolute;
					left: 0;
					bottom: -55px;
					width: 100px;
					height: 10px;
					background-color: $black;
				}
			}
		}

		.header__video{
			background-color: #F2F2F2;
			position: relative;

			.arrow-wrap{
				position: absolute;
			    bottom: -20px;
			    right: 15px;
			}
		}
	}
}

/**
 * Intro content
 **/

.intro-content{
	.has-image{
		margin-top: 5px;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}