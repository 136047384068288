.row:before, .row:after {
	display: none !important;
}

.posr{
	position: relative;
    width: 100%;
    height: 100%;
    display: block;	
}

/**
 * Backgrounds
 **/
@each $name, $color in ('red': $red, 'mustard': $mustard, 'purple': $purple, 'pink': $pink) {
	.bg--#{$name} {
		background-color: $color;
  	}
}

/**
 * Block
**/
@each $name, $size in ('10': 10, '20': 20, '35': 35, '50': 50, '75': 75, '100': 100, '150': 150) {
	.block-#{$name} {
		padding-top: #{$size}px;
		padding-bottom: #{$size}px;
  	}
}

.block-50-nt{
	padding-top: 0;
	padding-bottom: 50px;
}

@include media-breakpoint-down(sm) {
	.block-100.block-0-mobile{
		padding-top: 0;
		padding-bottom: 0;
	}
}

/**
 * Arrows
 **/
.arrow-wrap{
	background-color: $purple;
	border-radius: 25px; 
	display: block;
	height: 46px;
	width: 46px;

	.arrow{
		background-image: url('images/btn-arrow.svg');
	    height: 23px;
	    width: 20px;
	    display: inline-block;
	    position: relative;
	    background-repeat: no-repeat;
	    left: 14px;
	    top: 10px;

	    &.left{
	    	transform: rotate(90deg);
			left: 0px;
    		top: 13px;
	    }

	    &.right{
	    	transform: rotate(-90deg);
	    	left: 2px;
	    	top: 9px;
	    }
	}
}

.btn-wrap--black-transparent{
	.arrow-wrap .arrow{
		background-image: url('images/btn-arrow-black.svg');
	    transform: rotate(0);
	    background-size: 100%;
	    top: 14px;
	    left: 11px !important;		
	}
}

/**
 * Bounce animation
 **/

.anim-bounce {
  -moz-animation: bounce 2.5s infinite;
  -webkit-animation: bounce 2.5s infinite;
  animation: bounce 2.5s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-7px);
  }
  60% {
    transform: translateY(-5px);
  }
}