/**
 * Contact
 **/

.contactform-wrapper{
	background: $pink-lighter;

	@include media-breakpoint-down(sm){
		padding: 15px;
	}
}