/**
 *  Typography
 **/

*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
	font-family: $body-font;
}

h1,
h2,
h3,
h4,
h5,
h6{
	font-family: $heading-font;
}

h2{
	font-size: 60px;
	margin-bottom: 30px;

	@include media-breakpoint-down(md) {
		font-size: 36px;
	}
}

p{
	line-height: 26px;
}

img{
	max-width: 100%;
}

.text-center{
	text-align: center;
}

/**
 * Buttons
 **/

 .btn-wrap{
 	position: relative;
 	text-decoration: none;
 	
 	.arrow-wrap{
    	position: absolute;
    	right: -46px;
    	top: -12px;

    	.arrow{
    		position: absolute;

    		&.right{
    			left: 14px;
    		}
    	}	
 	}

 	&--black-transparent{
 		.arrow-wrap{
 			background-color: transparent;
 			border: 2px solid $black;
 		}
 	}
 }

.btn{
	border-radius: 50px;
	font-weight: 600;
	padding: 10.25px 40px;
	transition: all 350ms;

	&--black{
		background-color: $black;
		border: 2px solid $black;
		color: $white;
		text-decoration: none;

		&:hover{
			background-color: transparent;
			color: $black;
		}
	}

	&--black-transparent{
		border: 2px solid $black;
		color: $black;
		text-decoration: none;

		&:hover{
			background-color: $black;
			color: $white;
		}
	}

	&--purple-transparent{
		border: 2px solid $purple;
		color: $purple;
		text-decoration: none;

		&:hover{
			background-color: $purple;
			color: $white;
		}
	}
}