/**
 * Grid items -  General
 **/

.grid-item{	
	&__inner{
		display: block;

		&:hover{
			.grid-item__content{
				padding-left: 30px;
			}

			img{
				border-radius: 0 !important;
			}			
		}
	}

	&__content{
		padding: 20px 40px;
		position: relative;
		transition: all 350ms;
	}

	&__title,
	&__subtitle{
		color: $black;
	}

	&__title{
		font-family: $body-font;
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 0;

		@include media-breakpoint-up(xl){
			font-size: 20px;
		}
	}

	&__subtitle{

	}

	.arrow-next-wrapper{
		background-color: $black;
		border-radius: 25px; 
		height: 36px;
		width: 36px;
    	position: absolute;
    	right: 25px;
    	bottom: 15px;
		
		.arrow{
			background-image: url('images/btn-mustard.svg');
		    height: 23px;
		    width: 20px;
		    display: inline-block;
		    position: relative;
		    background-repeat: no-repeat;
		    left: 10px;
		    top: 10px;

		    &.left{
		    	transform: rotate(90deg);
				left: 0px;
	    		top: 13px;	    	
		    }

		    &.right{
		    	transform: rotate(-90deg);
		    	left: 2px;
		    	top: 9px;
		    }			
		}
	}

	&.bg--red{
		.arrow{
			background-image: url('images/btn-red.svg');			
		}
	}

	&.bg--mustard{
		.arrow{
			background-image: url('images/btn-mustard.svg');			
		}		
	}

	a{
		text-decoration: none;
	}

	img{
		width: 100%;
		transition: all 350ms ease-in-out;
	}
}

.borders-top{
	img{
		border-radius: 250px 250px 0px 0px;
	}
}

.borders-bottom{
	img{
		border-radius: 0px 0px 250px 250px;
	}
}

/**
 * Thema's grid
**/

.grid-item--theme{
	margin-bottom: 25px;
}

.grid-item--standard{
	margin-bottom: 25px;

	.grid-item__title{
		min-height: 50px;
	}
}

.theme--red-pink{
	.grid-item__inner{
		background-color: $red;
	}
}

.theme--purple-mustard{
	.grid-item__inner{
		background-color: $mustard;
	}
}

.theme--pink-red{
	.grid-item__inner{
		background-color: $pink;
	}
}

.theme--mustard-purple{
	.grid-item__inner{
		background-color: $purple;
	}
}

.theme--red-mustard{
	.grid-item__inner{
		background-color: $red;
	}
}