.post-type-post,
.page-template-default{

	h2,
	h3,
	h4{
		color: $red;
		font-family: $body-font;
		margin-top: 2rem;		
	}

	h3{
		font-size: 28px;
		line-height: 36px;
	}

	h2{
		font-size: 36px;
		line-height: 44px;
	}

	h3{
		font-size: 28px;
	}

	blockquote{
		text-align: center;
    	margin-top: 4rem;
    	margin-bottom: 4rem;


		p{
			color: $mustard;
			font-size: 36px;
			font-family: $body-font;
			line-height: 44px;
		}

		a{
			color: $mustard !important;
			font-weight: 500 !important;
		}
	}

	.article-body{
		max-width: 1024px;
		margin-bottom: 50px;

		a{
			color: $pink;
			font-weight: 700;
		}
	}
}