.thema-image{
	img{
		margin-top: -180px;

		@include media-breakpoint-down(lg) {
			display: none;
			//margin-top: 30px; 
		}
	}
}

.theme-description__inner{
	overflow: hidden;
}