/**
 * Intro header
 **/

.intro{
	&__header{
		h1{
			font-size: 60px;
			margin-bottom: 0;

			@include media-breakpoint-down(md) {
				font-size: 36px;
			}			
		}
	}

	&__content{
		ul{
			list-style-type: none;
			padding-left: 0;
		}

		a{
			color: $pink;
		}

		&--default{
			img{
				margin-top: -180px;
			}

			@include media-breakpoint-down(lg){
				display: none;
			}
		}
	}
}