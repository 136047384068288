/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$primary-color: #FFFFFF;
$secondary-color: #FFFFFF;

$red: #DB4C13;
$mustard: #F0C140;
$purple: #7E79CD;
$pink: #F4BB9C;
$pink-lighter: rgba(#F4BB9C, 0.2);

$background: #F2F2F2;
$white: #FFFFFF;
$black: #000000;

/**
 * Fonts
 **/

$heading-font: 'Bebas Neue', cursive;
$body-font: 'Nunito', sans-serif;